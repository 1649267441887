@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./theme/preorder";
@import "./theme/variables";

ion-content{
  --keyboard-offset: 0 !important;
}


.hide-scroll{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hide-scroll::-webkit-scrollbar{
  display: none;  /* Safari and Chrome */
}

.ig-gradient {
  background-image: linear-gradient(to bottom, #4f5bd5, #962fbf, #d62976, #feda75, #fa7e1e);
}
.instagram-gradient {
  background: radial-gradient(circle at 30% 107%, #feda75 0%, #fa7e1e 25%, #d62976 50%, #962fbf 75%, #4f5bd5 100%);
}
.instagram-logo {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.ball-bounce {
  animation: bounce 2s infinite;
}

.ball-bounce:nth-child(1) {
  animation-delay: 0s;
}

.ball-bounce:nth-child(2) {
  animation-delay: 0.2s;
}

.ball-bounce:nth-child(3) {
  animation-delay: 0.4s;
}

